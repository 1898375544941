import React, { Component } from 'react'
import {
  updateProductPricing
} from '../../utils/selectors'

class PriceListProduct extends Component {
  constructor(props) {
    super(props)
    let data = this.props.data
    let product = data.markdownRemark.frontmatter
    let updatedprices = updateProductPricing(product)
    this.state = {
      prices: updatedprices
    }
  }

  componentDidMount(){

  }

  componentDidUpdate(prevProps) {

  }

  render() {

    return(
      <>
        <h1>{this.props.productName}</h1>
        <h4>{this.props.category}</h4>
        <textarea style={{
          width: '100%',
          minHeight: '60vh',
          fontFamily: 'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
          fontSize: '.7rem',
          lineHeight: '1.25'
        }}>
          {this.state.prices}
        </textarea>
      </>
    )
  }
}

export default PriceListProduct
