import React from 'react'
import PriceList from '../components/PriceListProduct'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const App = ({data, pageContext}) => {
  let category = pageContext?.category
  let productName = pageContext?.productName
  return(
    <Layout>
    <PriceList data={data} productName={productName} category={category} context={pageContext} />
    </Layout>
  )
}

export default App

export const query = graphql`
query($id: String!) {
  markdownRemark(id: {eq: $id}) {
    frontmatter {
      product_name
      configurator_id
      smart_code
      product_category
      sort_name
      pricing_options {
        description
        model
        weight
        weight_options
        unit_text
        modified_standard
        base_configuration
        net_price {
          currency
          value
          pricing_schedule
        }
        smart_codes_selected {
          position
          smart_code
        }
      }
    }
    id
  }
}
`
